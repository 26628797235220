.bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo {

  height: 100px;
}

.home-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}